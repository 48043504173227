import { inject } from '@angular/core';
import { CanActivateFn } from '@angular/router';

import { filter, map } from 'rxjs/operators';

import { SystemService } from '../system/system.service';
import { InitializationStateEnum } from '../system/enum/initialization-state.enum';

export const authGuardService: CanActivateFn = () => {
    const systemService = inject(SystemService);

    return systemService.watchInitialized().pipe(
        filter((state) => InitializationStateEnum.INITIALIZED === state),
        map(() => {
            return !!systemService.getLocalStorage('token') && !!systemService.user;
        })
    );
};

