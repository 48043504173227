import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Router } from '@angular/router';

import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { SystemService } from '../system/system.service';

@Injectable({ providedIn: 'root' })
export class ErrorInterceptorService implements HttpInterceptor {
    constructor(
        private systemService: SystemService,
        private router: Router
    ) {}

    public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(error => {
            if (error.status === 401) {
                this.systemService.signOut();
            } else if (error.status === 403) {
                this.router.navigate(['/error'], { fragment: '403'});
            }
            return throwError(error);
        }));
    }
}
