import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { AbstractComponent } from '../abstract/abstract.component';
import { MessageDialogModel } from './model/message-dialog.model';
import { MessageDialogInteractionModel } from './model/message-dialog-interaction.model';

@Component({
    selector: 'app-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss']
})
export class MessageDialogComponent extends AbstractComponent {
    constructor(
        public dialogRef: MatDialogRef<MessageDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: MessageDialogModel,
    ) {
        super();
    }

    public eventFollowLink(): void {
        this.dialogRef.close({ followLink: true } as MessageDialogInteractionModel);
    }
    public eventRefresh(): void {
        this.dialogRef.close({ refresh: true } as MessageDialogInteractionModel)
    }
}
