import { StateModel } from '../shared/state/state.model';

export const mainStates: Array<StateModel> = [
    {
        path: 'sample',
        name: 'Amostras',
        icon: 'fa-solid fa-microscope',
        fontSize: '22px'
    }, {
        path: 'workflow-instance',
        name: 'Análises',
        icon: 'fa-solid fa-layer-group',
    }, {
        path: 'exam',
        name: 'Exames',
        icon: 'fa-solid fa-flask-vial'
    }
];

export const managementStates: Array<StateModel> = [
    {
        path: 'user-management',
        name: 'Usuários',
        icon: 'fa-solid fa-user-gear'
    }
    /* Depreciado até ajustes finais
    {
         path: 'workflow-definition',
         name: 'Fluxos de Pipeline',
         icon: 'fa-solid fa-diagram-project'
    */
]
