import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { SignInResultModel } from './model/sign-in-result.model';
import { SignInModel } from './model/sign-in.model';
import { UserModel } from './model/user.model';

@Injectable({
    providedIn: 'root'
})
export class SignService {
    BASE = environment.SIGN;
    constructor(
        protected httpService: HttpClient
    ) { }

    public signIn(signForm: SignInModel): Observable<SignInResultModel> {
        return this.httpService.post<SignInResultModel>(`${this.BASE}/sign/in`, signForm, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
    }
    public signOut(sessionId: string): Observable<void> {
        return this.httpService.delete<void>(`${this.BASE}/sign/out/${sessionId}`);
    }
    public getUserInformation(userId: string): Observable<UserModel> {
        return this.httpService.get<UserModel>(`${this.BASE}/user/${userId}`);
    }
}
