<div [ngClass] = "{
    'ui-badge-circle': shape === 'circle',
    'ui-badge-rectangle': shape === 'rectangle',
    'ui-badge-normal': type === 'normal',
    'ui-badge-error': type === 'error',
    'ui-badge-warning': type === 'warning',
    'ui-badge-success': type === 'success',
    'ui-badge-activated': type === 'activated'
}">
    {{ value }}
</div>
