<div class = "ui-nav-bar-system-container">
    <img src = "./assets/favicon.png">
</div>
<div class = "ui-divisor"></div>
<div class = "ui-nav-bar-states-container">
    <div class = "ui-nav-bar-state-container">
        <div [ngClass] = "{
            'ui-nav-bar-state': true,
            'ui-nav-bar-state-selected': state.path === actualState?.path
        }" *ngFor = "let state of states.main">
            <a mat-button class = "ui-button" [routerLink] = "state.path" [matTooltip] = "state.name">
                <span [class] = "state.icon" [style.font-size] = "state.fontSize"></span>
            </a>
            <div class = "ui-nav-bar-state-bar"></div>
        </div>
    </div>
    <div class = "ui-divisor"></div>
    <div class = "ui-nav-bar-state-container">
        <div [ngClass] = "{
            'ui-nav-bar-state': true,
            'ui-nav-bar-state-selected': state.path === actualState?.path
        }" *ngFor = "let state of states.management">
            <a mat-button class = "ui-button" [routerLink] = "state.path" [matTooltip] = "state.name">
                <span [class] = "state.icon" [style.font-size] = "state.fontSize"></span>
            </a>
            <div class = "ui-nav-bar-state-bar"></div>
        </div>
    </div>
</div>
<div class = "ui-divisor"></div>
<div class = "ui-nav-bar-user-container">
    <button mat-button class = "ui-button ui-circle-button" [mat-menu-trigger-for] = "userMenu">
        <span class  = "fa-solid fa-circle-user"></span>
    </button>
</div>

<mat-menu #userMenu = "matMenu" class = "ui-menu">
    <div class = "ui-menu-container">
        <button mat-button class = "ui-button" (click) = "eventSignOut()">
            Sair
        </button>
    </div>
</mat-menu>
