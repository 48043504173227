import { Component, Input } from '@angular/core';
import { BadgeTypeEnum } from './enum/badge-type.enum';

@Component({
    selector: 'app-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss']
})
export class BadgeComponent {
    @Input() type: BadgeTypeEnum | string = BadgeTypeEnum.NORMAL;
    @Input() shape: 'circle' | 'rectangle' = 'rectangle';
    @Input() value: string | undefined = undefined;
}
